import { TradingDocument } from "api/trading-documents/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "../../CreateDraftDocument.module.css";
import { currenciesToPick } from "CONSTANTS";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { MdiRadioButtonChecked } from "components/miloDesignSystem/atoms/icons/MdiRadioButtonChecked";
import { MdiRadioButtonUnchecked } from "components/miloDesignSystem/atoms/icons/MdiRadioButtonUnchecked";
import { cx } from "utilities";

interface Props {
  draftInvoice: TradingDocument;
}

export const CurrencySelector = ({ draftInvoice }: Props) => {
  const isDefaultCurrency = (currency: string): boolean => draftInvoice.currency === currency;

  return (
    <div className="d-flex align-items-center gap-2">
      <div className="d-flex align-items-center">
        <Typography color="neutralBlack48" fontSize="10" fontWeight="700">
          Waluta:
        </Typography>
      </div>
      <div className={styles.currencySelector}>
        {currenciesToPick
          .filter(currency => currency.value !== "DKK" && currency.value !== "RON")
          .map(currency => (
            <div
              className={cx({
                [styles.activeTag]: isDefaultCurrency(currency.value),
                [styles.disabledTag]: !isDefaultCurrency(currency.value),
              })}
            >
              <Tag
                disabled={!isDefaultCurrency(currency.value)}
                label={currency.label}
                startIcon={
                  isDefaultCurrency(currency.value)
                    ? MdiRadioButtonChecked
                    : MdiRadioButtonUnchecked
                }
                variant={isDefaultCurrency(currency.value) ? "deepPurple50" : "outline"}
              />
            </div>
          ))}
      </div>
    </div>
  );
};
