import { TradingDocument } from "api/trading-documents/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "../../CreateDraftDocument.module.css";
import { DatePicker } from "components/miloDesignSystem/molecules/datepicker/DatePicker";
import { usePatchTradingDocumentMutation } from "api/trading-documents/hooks";
import { getIsoDateFormat, getStandardDateFormat } from "utilities";
import { RecipientDetails } from "./RecipientDetails";
import { CurrencySelector } from "./CurrencySelector";
import { Select } from "components/miloDesignSystem/molecules/select";
import { useSelector } from "hooks";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";

interface Props {
  draftInvoice: TradingDocument;
}

export const GeneralInfoSection = ({ draftInvoice }: Props) => {
  const sellers = useSelector(store => store.partials.businessEntities);

  const sellersOptions = sellers
    .filter(seller => seller.kind === "INTERNAL")
    .map(seller => ({
      text:
        seller.id === draftInvoice.seller?.id
          ? `${seller.companyName} (domyślnie)`
          : seller.companyName,
      type: MenuItemType.TEXT,
      value: seller.id,
    }));

  return (
    <div className="d-flex align-items-center justify-content-between w-100">
      <div>
        <div className="d-flex flex-column gap-2 mb-2">
          <Typography color="neutralBlack88" fontSize="14" fontWeight="700">
            Sprzedawca
          </Typography>
          <div className={styles.sellerSelectorContainer}>
            <Select.Async
              textFieldProps={{
                size: "default",
              }}
              items={sellersOptions}
              label="Wybierz sprzedawcę"
              mutationHook={usePatchTradingDocumentMutation}
              selected={draftInvoice.seller?.id ?? null}
              transformQueryData={value => {
                const foundSeller = sellers.find(seller => seller.id === value);
                return {
                  id: draftInvoice.id,
                  seller: foundSeller || "",
                  sellerCity: foundSeller?.city || "",
                  sellerCompanyName: foundSeller?.companyName || "",
                  sellerEmail: foundSeller?.email || "",
                  sellerFirstName: foundSeller?.firstName || "",
                  sellerLastName: foundSeller?.lastName || "",
                  sellerPhone: foundSeller?.phone || "",
                  sellerPostCode: foundSeller?.postCode || "",
                  sellerStreet: foundSeller?.address || "",
                  sellerTaxId: foundSeller?.taxId || "",
                };
              }}
            />
          </div>
        </div>
        <div className={styles.invoiceDatesContainer}>
          <DatePicker.Async
            disabled={draftInvoice.status === "CONFIRMED"}
            isNullable
            mutationHook={usePatchTradingDocumentMutation}
            transformQueryData={value => ({
              id: draftInvoice.id,
              invoiceIssueDate: value ? getIsoDateFormat(value) : value,
            })}
            label="Data wystawienia"
            value={draftInvoice.invoiceIssueDate}
          />
          <DatePicker.Async
            disabled={draftInvoice.status === "CONFIRMED"}
            isNullable
            mutationHook={usePatchTradingDocumentMutation}
            transformQueryData={value => ({
              id: draftInvoice.id,
              invoiceDeliveryDate: value ? getIsoDateFormat(value) : value,
            })}
            label="Data sprzedaży"
            value={draftInvoice.invoiceDeliveryDate}
          />
          <div className="d-flex align-items-center gap-2">
            <div className="d-flex align-items-cneter">
              <Typography color="neutralBlack48" fontSize="10" fontWeight="700">
                Data utworzenia:
              </Typography>
            </div>
            <Typography color="neutralBlack88" fontSize="14" fontWeight="700">
              {getStandardDateFormat(draftInvoice.createdAt)}
            </Typography>
          </div>
          <CurrencySelector draftInvoice={draftInvoice} />
        </div>
      </div>
      <RecipientDetails draftInvoice={draftInvoice} />
    </div>
  );
};
