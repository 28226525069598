import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import styles from "./CreateDraftDocument.module.css";
import { useParams } from "react-router";
import { useTradingDocument } from "api/trading-documents/hooks";
import { CommonError } from "components/utils";
import { cx } from "utilities";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { HeaderSection } from "./subcomponents/headerSection/HeaderSection";
import { Button } from "components/miloDesignSystem/atoms/button";
import { ActionButtonsSection } from "./subcomponents/actionButtonsSection/ActionButtonsSection";
import { useNavigate } from "hooks/useNavigate";
import { DocumentPositions } from "./subcomponents/documentPositions/DocumentPositions";
import { SummarySection } from "./subcomponents/summarySection/SummarySection";
import { PaymentDetailsSection } from "./subcomponents/paymentDetailsSection/PaymentDetailsSection";
import { UUID } from "api/types";
import { GeneralInfoSection } from "./subcomponents/generalInfoSection/GeneralInfoSection";
import { ExchangeRateSection } from "./subcomponents/exchangeRateSection/ExchangeRateSection";

export const CreateDraftDocument = () => {
  const params = useParams<{ id: UUID }>();
  const { data: draftInvoice, error, isLoading } = useTradingDocument(
    { id: params.id },
    { enabled: Boolean(params.id) },
  );
  const navigate = useNavigate();

  if (error)
    return (
      <PageWrapper>
        <PageHeader
          bigTitle="Faktura na podmiot własny"
          searchBar={false}
          viewLabel="CREATE_DRAFT_DOCUMENT"
        />
        <div
          className={cx(
            styles.mainContainer,
            "d-flex align-items-center justify-content-center mt-4",
          )}
        >
          <CommonError status={error._httpStatus_} />
        </div>
      </PageWrapper>
    );

  if (isLoading)
    return (
      <PageWrapper>
        <PageHeader
          bigTitle="Faktura na podmiot własny"
          searchBar={false}
          viewLabel="CREATE_DRAFT_DOCUMENT"
        />
        <div className={styles.mainContainer}>
          <Spinner size={26} />
        </div>
      </PageWrapper>
    );

  if (!draftInvoice) return null;

  return (
    <PageWrapper>
      <PageHeader
        bigTitle="Faktura na podmiot własny"
        searchBar={false}
        viewLabel="CREATE_DRAFT_DOCUMENT"
      />
      <div className={styles.mainContainer}>
        <div className={styles.content}>
          <HeaderSection draftInvoice={draftInvoice} />
          <div className={styles.contentBody}>
            <GeneralInfoSection draftInvoice={draftInvoice} />
            <div className={styles.dashedBorder} />
            <ActionButtonsSection draftInvoice={draftInvoice} />
            <DocumentPositions draftInvoice={draftInvoice} />
            <div className={styles.summarySection}>
              <SummarySection draftInvoice={draftInvoice} />
            </div>
            <PaymentDetailsSection draftInvoice={draftInvoice} />
            <ExchangeRateSection draftInvoice={draftInvoice} />
          </div>
          <div className={styles.contentHeader}>
            <Button
              className="text-uppercase"
              onClick={() => navigate(`/finances/sales/list/all?panelId=${draftInvoice.id}`)}
              size="medium"
              variant="deepPurple"
            >
              Gotowe
            </Button>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};
