import { useProductEntity } from "api/products/hooks";
import { Product } from "api/products/models";
import { AddProductToDraftInvoicePayload, TradingDocument } from "api/trading-documents/models";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import cuid from "cuid";
import { Formik } from "formik";
import immer from "immer";
import { initialProductElement } from "pages/orders/shared/rightPanel/productsSection/productForm/utils/initialState";
import { getInitialStateForCreate } from "pages/wms/unloadingsList/rightPanel/components/ordersSection/addIndexToUnloading/getInitialStateForCreate";
import { useMemo } from "react";
import { cx } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import styles from "../../AddProductToDraft.module.css";
import { Button } from "components/miloDesignSystem/atoms/button";
import { reducer } from "pages/wms/unloadingsList/rightPanel/components/ordersSection/addIndexToUnloading/AddIndexToUnloadingModal";
import { AttributesForm } from "./AttributesForm";
import { financesActions } from "api/finances/actions";
import { FormikErrorMessage } from "components/utils/errorMessage/FormikErrorMessage";

interface Props {
  close: () => void;
  draftInvoice: TradingDocument;
  product: Product;
}

export const ProductForm = ({ close, draftInvoice, product }: Props) => {
  const [productEntity, { inProgress: isFetchingProduct }] = useProductEntity(product.id, {
    reducer,
    skip: !product,
    clearSkip: true,
  });

  const handleSubmit = financesActions.useAddProductToDraftDocument(close);

  const initialValues: AddProductToDraftInvoicePayload = useMemo(() => {
    if (!productEntity?.id)
      return {
        tradingDocumentId: draftInvoice.id,
        indexId: null,
        quantity: 1,
        discount: 0,
        amountWithTax: 0,
        orderId: null,
        name: "",
        vatRate: 0,
        productElements: [
          {
            ...initialProductElement,
            product: 0,
            cuid: cuid(),
            attributesState: [],
            productSetCode: "",
            id: 0,
            name: "",
            amount: "0",
            currency: "PLN",
          },
        ],
      };
    return {
      tradingDocumentId: draftInvoice.id,
      indexId: null,
      amountWithTax: 0,
      discount: 0,
      quantity: 1,
      orderId: null,
      name: productEntity.name,
      vatRate: 0,
      productElements: getInitialStateForCreate({
        products: productEntity.products,
        productSetCode: productEntity.productSetCode,
      }),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productEntity?.id, draftInvoice.id]);

  const validate = (values: AddProductToDraftInvoicePayload) => {
    const errors: Partial<{
      productElements: string;
    }> = {};
    if (!values.productElements.every(productElement => productElement.index)) {
      errors.productElements = productEntity?.productSetCode
        ? "Wybierz wszystkie warianty produktów"
        : "Wybierz istniejący wariant produktu";
    }
    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validate={validate}
    >
      {({ handleSubmit, isSubmitting, isValid, values, setValues, setFieldValue }) => (
        <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
          <div>
            {isFetchingProduct && <Spinner size={26} />}
            {productEntity &&
              values.productElements.map((value, index) => (
                <div className={cx({ "bg-white": index % 2 !== 0 })} key={value.cuid}>
                  <AttributesForm
                    product={productEntity.products[index]}
                    setFieldValue={(name, value) => {
                      setFieldValue(`productElements[${index}][${name}]`, value);
                    }}
                    values={values.productElements[index]}
                    changeAttribute={(attributeId, value, cuid) =>
                      setValues(
                        immer(values, draft => {
                          const productToUpdate = draft.productElements.find(
                            productElement => productElement.cuid === cuid,
                          );
                          assertIsDefined(productToUpdate);
                          const toChange = productToUpdate.attributesState.find(
                            attribute => attribute.attributeId === attributeId,
                          );
                          if (toChange) {
                            toChange.valueId = value;
                          }
                        }),
                      )
                    }
                  />
                </div>
              ))}
            <FormikErrorMessage className={styles.errorMessage} name="productElements" />
          </div>
          <div className={styles.buttons}>
            <Button className="text-uppercase" onClick={close} size="medium" variant="transparent">
              Anuluj
            </Button>
            <Button
              className="text-uppercase"
              disabled={!product}
              isLoading={isSubmitting}
              size="medium"
              type="submit"
              variant="deepPurple"
            >
              Dodaj do faktury
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
