import { Pagination, UUID } from "api/types";
import { PartialOf } from "typeUtilities";
import {
  BulkSalesInvoiceConfirmPreview,
  CreateCorrectionDocumentPayload,
  CreateDraftDocumentPreview,
  CreatePricing,
  LightTradingDocument,
  ModifyDraftDocumentPosition,
  ModifyTradingDocument,
  PatchTradingDocumentItemsVatRatePayload,
  PreviewTradingDocument,
  SendEmailNotificationToMultiple,
  TradingDocument,
} from "./models";
import { queryFetch } from "apiConnectors/queryFetch";
import { parsePatchData } from "utilities/parsePatchData";
import { Assign } from "utility-types";
import { ApiFetcher } from "hooks/createApiQuery";
import { tradingDocumentsKeys } from "./keys";

const getLightTradingDocuments = (
  search: string = "",
): ApiFetcher<Pagination<LightTradingDocument>> => ({
  key: tradingDocumentsKeys.lightTradingDocument.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/finances/trading-documents/light" + search,
    }),
});

const getImportedPurchaseInvoice = ({ id }: { id: UUID }): ApiFetcher<TradingDocument> => ({
  key: tradingDocumentsKeys.importedPurchaseInvoices.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/finances/trading-documents/purchase/items/" + id,
    }),
});

const bulkUpdateTradingDocuments = (args: {
  ids: UUID[];
  toUpdate: Assign<Omit<PartialOf<TradingDocument>, "ledgerAccount">, { ledgerAccount?: UUID }>;
}) =>
  queryFetch<{ message: "ok" }>({
    method: "PATCH",
    url: "/finances/trading-documents/items/bulk-update",
    data: {
      ...parsePatchData(args.toUpdate),
      ids: args.ids,
    },
  });

const patchTradingDocumentItemsVatRate = (data: PatchTradingDocumentItemsVatRatePayload) =>
  queryFetch<void>({
    method: "PATCH",
    url: "/finances/trading-documents-items/set-vat-rate",
    data,
  });

const patchRemoveInvoiceConfirmation = (data: { tradingDocuments: UUID[] }) =>
  queryFetch<void>({
    method: "PATCH",
    url: "/developers/trading-documents/unconfirm",
    data,
  });

const postBulkConfirmSalesInvoice = (data: { tradingDocumentsIds: UUID[] }) =>
  queryFetch<{ message: BulkSalesInvoiceConfirmPreview }>({
    method: "POST",
    url: "/finances/trading-documents/bulk-confirm-sales-invoices",
    data,
  });

const postFiscalizeMultipleReceipts = (data: { tradingDocuments: UUID[] }) =>
  queryFetch<void>({
    method: "POST",
    url: "/finances/trading-documents/print-receipts",
    data,
  });

const postMultipleEmailNotifications = (data: SendEmailNotificationToMultiple) =>
  queryFetch<void>({
    method: "POST",
    url: "/finances/trading-documents/send-to-recipient",
    data,
  });

const postCorrectionDocumentForInvoice = (data: CreateCorrectionDocumentPayload) =>
  queryFetch<void>({
    method: "POST",
    url: "/finances/trading-documents/create-correction-document",
    data,
  });

const postPreviewCorrection = (data: { tradingDocument: string | number }) =>
  queryFetch<PreviewTradingDocument>({
    method: "POST",
    url: "/finances/trading-documents/preview-correction-document",
    data,
  });

const patchImportedPurchaseInvoice = (data: Assign<Partial<ModifyTradingDocument>, { id: UUID }>) =>
  queryFetch<TradingDocument>({
    method: "PATCH",
    url: "/finances/trading-documents/purchase/items/" + data.id,
    data: parsePatchData(data),
  });

const postWorkInProgressDocument = (data: { businessEntityId: UUID }) =>
  queryFetch<CreateDraftDocumentPreview>({
    method: "POST",
    url: "/finances/trading-documents/create-work-in-progress-document",
    data,
  });

const postPricing = (data: CreatePricing) =>
  queryFetch<void>({
    method: "POST",
    url: "/finances/trading-documents/apply-price-list-to-invoice-positions",
    data,
  });

const deleteDraftDocumentPosition = (id: UUID) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/finances/trading-documents-items/work-in-progress-items/" + id,
  });

const patchDraftDocumentPosition = (
  data: Assign<Partial<ModifyDraftDocumentPosition>, { id: UUID; tradingDocumentId: UUID }>,
) =>
  queryFetch<ModifyDraftDocumentPosition>({
    method: "PATCH",
    url: "/finances/trading-documents-items/work-in-progress-items/" + data.id,
    data: parsePatchData(data),
  });

const postResetPrices = (data: { tradingDocumentId: UUID }) =>
  queryFetch<void>({
    method: "POST",
    url: "/finances/trading-documents/reset-prices",
    data,
  });

export const tradingDocumentsApi = {
  bulkUpdateTradingDocuments,
  patchTradingDocumentItemsVatRate,
  postBulkConfirmSalesInvoice,
  postFiscalizeMultipleReceipts,
  getLightTradingDocuments,
  postMultipleEmailNotifications,
  postCorrectionDocumentForInvoice,
  postPreviewCorrection,
  getImportedPurchaseInvoice,
  patchImportedPurchaseInvoice,
  patchRemoveInvoiceConfirmation,
  postWorkInProgressDocument,
  deleteDraftDocumentPosition,
  patchDraftDocumentPosition,
  postPricing,
  postResetPrices,
};
