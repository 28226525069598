import { CURRENCY_TYPE } from "CONSTANTS";
import { DeliveryMethodType } from "api/orders/models";
import { Configuration, ProductionSettings } from "api/other/models";
import { Category } from "api/products/models";
import { Seller } from "api/sellers/models";
import { UUID } from "api/types";
import { BaseUser } from "api/users/models";
import { VatRate } from "api/vat-rates/models";
import { Assign } from "utility-types";

export interface Labels {
  warehouse: {
    orientation: string;
    height: string;
    width: string;
    showQrCode: boolean;
    showBarcode: boolean;
    showPicture: boolean;
    showLogo: boolean;
  };
  production: {
    orientation: string;
    height: string;
    width: string;
    showQrCode: boolean;
    showBarcode: boolean;
    showPicture: boolean;
  };
  transport: {
    orientation: string;
    height: string;
    width: string;
    showQrCode: boolean;
    showBarcode: boolean;
    showPicture: boolean;
    showLogo: boolean;
  };
}

export type DeadlineDayOptions =
  | "TODAY"
  | "TOMORROW"
  | "AFTER_TOMORROW"
  | "NEAREST_MONDAY"
  | "NEAREST_FRIDAY";

export interface ConfirmationDateOptions {
  id: string;
  deadlineTime: string;
  deadlineDayOption: DeadlineDayOptions;
}

export interface PostConfirmationDateOptions {
  deadlineTime: string;
  deadlineDayOption: string;
}

export enum WhereIsDriverShowTimeInterval {
  CALCULATED = "CALCULATED",
  CONFIRMED = "CONFIRMED",
}

export interface Transport {
  addToLeftRange: number;
  addToRightRange: number;
  countAmountOnMapFromDeliveryCost: boolean;
  defaultPauseBetweenRoutePoint: number;
  defaultRouteStartTime: string;
  defaultDeliveryMethod: DeliveryMethodType;
  driverDeliveryHourEnd: string;
  includeLastPointInOptimization: boolean;
  showDailyProductionInfo: boolean;
  showStopoverOnRoutePdf: boolean;
  driverDeliveryHourStart: string;
  driverWorkHoursAlign: boolean;
  driverWorkHoursFromMaxSpacing: number;
  driverWorkHoursFromMinSpacing: number;
  hasCategoriesCountersOnMap: boolean;
  hasSmsAutomaticallySendForOrdersOnRoute: boolean;
  showOnMapOrdersOnWayToWarehouse: boolean;
  hasDeadlineConfirmationDate: boolean;
  countVolumeAndWeighOnMapBy: string;
  defaultVehicleType: "car" | "small_truck" | "truck";
  options: ConfirmationDateOptions[];
  id: number;
  lastModified: string;
  roundType: "NONE" | "HALF" | "FULL" | "FULL_HOUR_DOWN";
  timeForAutomaticSmsSend: string | null;
  whereIsDriverShowTimeInterval: WhereIsDriverShowTimeInterval;
}

export interface Production {
  createProductionOrdersAutomatically: boolean;
  id: number;
  lastModified: string;
  showDailyProductionInfo: boolean;
}

export interface RightPanelDisplay {
  deliverySection: {
    email: boolean;
    login: boolean;
    manualDeliveryPoint: boolean;
  };
  name: boolean;
  orderGroupSection: {
    name: boolean;
    pickUpDate: boolean;
    pickUpTime: boolean;
    signature: boolean;
  };
  hasProducedItems: boolean;
  invoiceData: boolean;
  messageToSeller: boolean;
  comments: boolean;
  warehousesDeliveryDateSectionPresence: boolean;
  routeSection: {
    signature: boolean;
    showNameInsteadOfSignature: boolean;
    deliveryMethod: boolean;
    collectionDate: boolean;
    minimumDeliveryDate: boolean;
    clientConfirmation: boolean;
    exportSpecificationButton: boolean;
    driver: boolean;
    departureDate: boolean;
    length: boolean;
    shippingService: boolean;
    hasCarrying: boolean;
    isAssemblingRequested: boolean;
    previousRoutes: boolean;
    previousGroups: boolean;
    isShipped: boolean;
    isDelivered: boolean;
    isSettled: boolean;
  };
  productsSection: {
    offerNumber: boolean;
    notes: boolean;
    externalNumber: boolean;
    isPrepared: boolean;
    hasReservation: boolean;
    availabilityButton: boolean;
    listingsComparisonButton: boolean;
  };
  contactSection: {
    sms: boolean;
    email: boolean;
    isCallRequired: boolean;
    isWaitingForCustomerCall: boolean;
  };
  paymentSection: {
    currency: boolean;
    type: boolean;
    deliveryAmount: boolean;
    discount: boolean;
    status: boolean;
    isReturn: boolean;
    isInvoiceRequired: boolean;
    isVerificationRequired: boolean;
    isExternalRelease: boolean;
    hasTransportBeenInvoiced: boolean;
    hasItemsBeenInvoiced: boolean;
    hasTransportBeenSettled: boolean;
    hasItemsBeenSettled: boolean;
  };
}

export type CustomerSalesAccountLabelsSetting =
  | "ONLY_CUSTOMER_LABELS_VISIBLE_IN_ORDER_LIST"
  | "ONLY_SALES_ACCOUNT_LABELS_VISIBLE_IN_ORDER_LIST"
  | "CUSTOMER_LABELS_VISIBLE_IF_NOT_SALES_ACCOUNT_VISIBLE_IN_ORDER_LIST"
  | "SALES_ACCOUNT_LABELS_VISIBLE_IF_NOT_CUSTOMER_VISIBLE_IN_ORDER_LIST"
  | "CUSTOMER_AND_SALES_ACCOUNT_LABELS_VISIBLE_IN_ORDER_LIST"
  | "CUSTOMER_AND_SALES_ACCOUNT_LABELS_NOT_VISIBLE_IN_ORDER_LIST";

export interface Sales {
  b2b: {
    deliveryMethods: { external: boolean; personalCollection: boolean; own: boolean };
    isPhoneRequired: boolean;
    isEmailRequired: boolean;
    listDisplay: {
      orderStatus: boolean;
    };
    paymentType: {
      cashOnDelivery: boolean;
      online: boolean;
      installment: boolean;
      debitCard: boolean;
    };
    rightPanelDisplay: RightPanelDisplay;
  };
  hasSignatureOrderNumber: boolean;
  isEmailRequired: boolean;
  isPhoneRequired: boolean;
  rightPanelDisplay: RightPanelDisplay;
  customerSalesAccountLabelsInOrderList: CustomerSalesAccountLabelsSetting;
  customerSalesAccountLabelsInOrderListB2b: CustomerSalesAccountLabelsSetting;
  listDisplay: {
    signature: boolean;
    name: boolean;
    arrivalDate: boolean;
    salesAccountName: boolean;
    priceListName: boolean;
    clientName: boolean;
    deliveryAddress: boolean;
    orderStatus: boolean;
    numberOfLeftDays: boolean;
    invoicePaidStatus: boolean;
    phoneContactStatus: boolean;
    assignedUser: boolean;
  };
  deliveryMethods: { external: boolean; personalCollection: boolean; own: boolean };
  paymentType: {
    installment: boolean;
    debitCard: boolean;
    online: boolean;
    cashOnDelivery: boolean;
  };
}

type VerificationStatus = "NOT_VERIFIED" | "VERIFICATION_ERROR" | "VERIFIED";

export interface Communication {
  contactPerson: {
    email: string;
    name: string;
    phone: string;
  };
  sms: {
    has2WaySms: boolean;
    hasSmsService: boolean;
    senderName: string;
    dedicatedSenderPhone: string;
    login: string;
    password: string;
    token: string;
    status: VerificationStatus;
    hasUtf: boolean;
    verificationMessage: string;
    isSmsUsingDedicatedPhoneNumber: boolean;
    sendTwoWaySmsOnDefault: boolean;
    sendSmsWhenDeliveryDateConfirmed: boolean;
  };
  email: {
    emailApiKey: string;
    emailForManufacturer: string;
    emailSecretKey: string;
    emailSmtpAccount: string;
    hasEmailForManufacturers: boolean;
    hasEmailForRoutes: boolean;
    hasEmailService: boolean;
    emailForRoute: string;
    sendEmailWhenOrderConfirmed: boolean;
    sendEmailWhenDeliveryDateConfirmed: boolean;
    status: VerificationStatus;
  };
}

export interface ExchangeRates {
  currency: {
    currency: string;
    id: UUID;
  };
  id: UUID;
  settings: UUID;
}

export interface CreateCurrencyToWatch {
  currency: UUID;
  settings: UUID;
}

export interface CreateCustomersToRemind {
  customer: number;
  tradingDocumentReminderSettings: UUID;
}

export interface CustomerToRemind {
  customer: {
    canAddNewIndexes: boolean;
    hasAmountToCollect: boolean;
    hasDropShipping: boolean;
    id: number;
    name: string;
  };
  id: UUID;
  tradingDocumentReminderSettings: UUID;
}

export const reminderSchedulePeriod: Record<ReminderSchedulePeriod, string> = {
  AFTER: "po terminie",
  AT_DEADLINE_DAY: "w dniu terminu",
  BEFORE: "przed terminem",
};

export type ReminderSchedulePeriod = "BEFORE" | "AFTER" | "AT_DEADLINE_DAY";

export interface ReminderSchedule {
  daysDelta: number;
  emailMessage: number;
  id: UUID;
  when: ReminderSchedulePeriod;
}

export interface ModifyReminderSchedule {
  daysDelta: number;
  emailMessage: number;
  when: ReminderSchedulePeriod;
}

export interface Warehouse {
  autoCloseWarehouseDocuments: boolean;
  createPackagesWhenCreatingProductsFromBaselinker: boolean;
  howManyPackagesCreateWhenCreatingProductsFromBaselinker: number;
  requiredSuperuserToCloseWarehouseDocuments: boolean;
}

export interface DefaultReviewer extends BaseUser {
  isMasterReviewer: boolean;
}

export interface AddDefaultReviewer {
  isMasterReviewer: boolean;
  user: number | null;
}

export interface AddDefaultReviewerPayload {
  id: number;
  isMasterReviewer: boolean;
  user: BaseUser;
}

export enum AutoCreateTradingDocumentStatus {
  NEVER = "NEVER",
  ON_SCANNED = "ON_SCANNED",
  ON_CONFIRMED = "ON_CONFIRMED",
  ON_CALL_PICKUP = "ON_CALL_PICKUP",
}

export const autoCreateTradingDocumentStatusDict: Record<
  AutoCreateTradingDocumentStatus,
  string
> = {
  NEVER: "nigdy nie twórz",
  ON_CALL_PICKUP: "po wysłaniu zamówień do spedycji",
  ON_CONFIRMED: "po oznaczeniu zamówienia jako gotowe",
  ON_SCANNED: "po zeskanowaniu ostatniej paczki na zamówieniu",
};

export const autoCreateTradingDocumentStatusOptions: {
  id: AutoCreateTradingDocumentStatus;
  name: string;
}[] = Object.entries(autoCreateTradingDocumentStatusDict).map(([status, label]) => ({
  id: status as AutoCreateTradingDocumentStatus,
  name: label,
}));

export enum OptimaCreateCustomer {
  ALWAYS = "ALWAYS",
  RECIPIENT_TAX_ID_ONLY = "RECIPIENT_TAX_ID_ONLY",
}

export enum AutoConfirmationWhen {
  NEVER = "NEVER",
  INVOICE_PAID = "INVOICE_PAID",
}

export enum ReceiptToTradingDocumentCreationStrategy {
  ONLY_PL_DETAIL_CUSTOMERS = "ONLY_PL_DETAIL_CUSTOMERS",
  ALL_PL_CUSTOMERS = "ALL_PL_CUSTOMERS",
  NEVER = "NEVER",
  ONLY_CASH_ON_DELIVERY_PL_DETAIL_CUSTOMERS = "ONLY_CASH_ON_DELIVERY_PL_DETAIL_CUSTOMERS",
}

export interface TradingDocument {
  autoConfirmationWhen: AutoConfirmationWhen;
  autoCreateReceiptForInvoice: boolean;
  autoCreateTradingDocument: AutoCreateTradingDocumentStatus;
  autoCreateAdvanceTradingDocument: AutoConfirmationWhen;
  automaticallyAddTransportToTradingDocument: boolean;
  defaultReviewers: DefaultReviewer[];
  defaultSellerForAutomaticallyDocuments: Seller | null;
  defaultTransportSellerForAutomaticallyDocuments: Seller | null;
  id: UUID;
  isDiffCategoryForSelfProduced: boolean;
  isDiffCategoryForTaxProcedure: boolean;
  optimaPurchaseCategoryId: string;
  optimaPurchaseCategoryName: string;
  optimaPurchaseDescription: string;
  optimaPurchaseRegister: string;
  optimaSalesCategoryForSelfProductionId: string;
  optimaSalesCategoryForSelfProductionName: string;
  optimaSalesCategoryId: string;
  optimaSalesCategoryName: string;
  optimaSalesCreateCustomer: OptimaCreateCustomer;
  optimaSalesDescription: string;
  optimaSalesDetailCustomerName: string;
  optimaSalesRegister: string;
  receiptToTradingDocumentCreationStrategy: ReceiptToTradingDocumentCreationStrategy;
  transportDocumentItemCode: string;
  transportDocumentItemName: string;
  transportOnSeparateTradingDocument: boolean;
  vatRateForTransport: VatRate | null;
}

export interface Reminders {
  autoSendTradingDocumentReminder: boolean;
  id: UUID;
  sendReminderOnlyTo: number[];
  sendReminderToEveryone: boolean;
}

export interface Owner {
  bankName: string;
  city: string;
  code: string;
  configuration: Configuration;
  countryCode: string;
  email: string;
  emailAccounts: string;
  emailPayment: string;
  emailRodo: string;
  fullName: string;
  id: number;
  mainAccountNumber: string;
  name: string;
  phone: string;
  phoneFax: string;
  postCode: string;
  primaryCurrency: CURRENCY_TYPE;
  productionSettings: ProductionSettings;
  secondaryAccountNumber: string;
  secondaryCurrency: CURRENCY_TYPE;
  street: string;
  swift: string;
  taxId: string;
  website: string;
}

export type PdfField =
  | "useDefaultProtocol"
  | "useDefaultGroupedProtocol"
  | "useDefaultSpecification"
  | "useDefaultDetailOrder"
  | "useDefaultOrdersPayments"
  | "useDefaultCmr"
  | "useDefaultCmrPerEachOrder"
  | "useDefaultComplaint"
  | "useDefaultRoute"
  | "useDefaultDetailRouteOrderList"
  | "useDefaultRouteItemsSummary"
  | "useDefaultPackageSlip"
  | "useDefaultPackageSlipGroupedByOrders"
  | "useDefaultReceipt"
  | "useDefaultDriver"
  | "useDefaultProduction"
  | "useDefaultProductionOrderGroup"
  | "useDefaultGroup"
  | "useDefaultOrderGroupItemsSummary"
  | "useDefaultProductionOrder"
  | "useDefaultSummaryProductionOrder"
  | "useDefaultUpholstery"
  | "useDefaultTradingDocument"
  | "useDefaultDocumentWithDeliveryAmount"
  | "useDefaultEmployee"
  | "useDefaultEmployeeWork"
  | "useDefaultEmployeeDaily"
  | "useDefaultCellsQrCodesForRow"
  | "useDefaultProductionOrderLabels"
  | "useDefaultLabelsTemplate"
  | "useDefaultLabel"
  | "useDefaultLabelForCustomer"
  | "useDefaultRouteOrderLabels"
  | "useDefaultWhRowLabels"
  | "useDefaultGroupedProductionLabels"
  | "useDefaultProductionOrderPackagesLabels"
  | "useDefaultProductionLabels";

export type PdfSettings = {
  [key in PdfField]: boolean;
};

export interface MiloSettings {
  labels: Labels;
  transport: Transport;
  production: Production;
  sales: Sales;
  communication: Communication;
  exchangeRates: ExchangeRates;
  warehouse: Warehouse;
  tradingDocument: TradingDocument;
  owner: Owner;
  reminders: Reminders;
  wms: CategoryVisibleInWmsTable;
  pdfs: PdfSettings;
}

export enum Tab {
  sales = "Sprzedaż",
  transport = "Transport",
  production = "Produkcja",
  labels = "Etykiety",
  communication = "Powiadomienia",
  exchangeRates = "Kursy walut",
  warehouse = "Magazyn",
  tradingDocument = "Dokument handlowy",
  owner = "Właściciel",
  reminders = "Przypomnienia",
  wms = "WMS",
  pdfs = "PDF",
}

export interface DeliveryHoursTestPayload {
  plannedDeliveryTime: string;
  settings: {
    driverDeliveryHourStart: string;
    driverDeliveryHourEnd: string;
    roundType: string;
    addToLeftRange: number;
    addToRightRange: number;
    driverWorkHoursAlign: boolean;
    driverWorkHoursFromMinSpacing: number;
    driverWorkHoursFromMaxSpacing: number;
  };
}

export interface CategoryVisibleInWmsTablePayload {
  settings: UUID;
  category: string | number;
}

export interface CategoryVisibleInWmsTable {
  category: Category;
  id: UUID;
  settings: UUID;
}

export interface DedicatedPhoneNumber {
  communicationSettings: number;
  countryCode: string;
  id: number;
  isDefault: boolean;
  phone: string;
  prefix: string;
}

export type CreateDedicatedPhoneNumber = Omit<DedicatedPhoneNumber, "id" | "communicationSettings">;

export type CreateScheduleDTO = Assign<
  Omit<ModifyReminderSchedule, "emailMessage">,
  { emailMessage: number | null }
>;
