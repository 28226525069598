import { TradingDocument } from "api/trading-documents/models";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "./Pricing.module.css";
import { Formik } from "formik";
import { cx } from "utilities";
import { useSelector } from "hooks";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Select } from "components/miloDesignSystem/molecules/select";
import { Radio } from "components/miloDesignSystem/atoms/radio";
import { useState } from "react";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { UUID } from "api/types";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiUndo } from "components/miloDesignSystem/atoms/icons/MdiUndo";
import { tradingDocumentsActions } from "api/trading-documents/actions";
import { createDraftDocumentUtils } from "../utils";

interface Props {
  close: () => void;
  draftInvoice: TradingDocument;
}

export interface CreatePricingForm {
  discount: number;
  margin: number;
  priceListId: number | "";
  tradingDocumentId: UUID;
}

export type Modifier = "DISCOUNT" | "MARGIN" | "NONE";

export const Pricing = ({ close, draftInvoice }: Props) => {
  const resetPricesMutation = tradingDocumentsActions.useResetPrices(close);
  const [modifierKind, setModifierKind] = useState<Modifier>("NONE");
  const priceLists = useSelector(store => store.partials.priceLists);
  const priceListsOptions = priceLists.map(priceList => ({
    text: priceList.name,
    type: MenuItemType.TEXT,
    value: priceList.id,
  }));

  const initialValues: CreatePricingForm = {
    discount: draftInvoice.priceModifier.amountModifiers?.discount
      ? Number(draftInvoice.priceModifier.amountModifiers?.discount)
      : 0,
    margin: draftInvoice.priceModifier.amountModifiers?.margin
      ? Number(draftInvoice.priceModifier.amountModifiers?.margin)
      : 0,
    priceListId: draftInvoice.priceModifier.priceList?.id ?? "",
    tradingDocumentId: draftInvoice.id,
  };

  const handleSubmit = tradingDocumentsActions.useAddPricing(close);

  return (
    <Modal
      close={close}
      isOpen
      width={640}
      title={
        <Typography fontSize="20" fontWeight="700">
          Ustal cenę
        </Typography>
      }
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(value, helpers) => handleSubmit(value, helpers, modifierKind)}
      >
        {({ handleSubmit, isSubmitting, isValid, values, setFieldValue }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div>
              <div className="d-flex flex-column gap-4 py-2 px-3">
                <div className="d-flex flex-column pb-2">
                  <div className="d-flex flex-column gap-1 pb-1 mb-2">
                    <Radio
                      checked={modifierKind === "DISCOUNT"}
                      label="Nalicz rabat"
                      onClick={() => {
                        setModifierKind("DISCOUNT");
                        setFieldValue("margin", 0);
                      }}
                      size="sm"
                    />
                    <div className={styles.inputSection}>
                      <TextField.Form<CreatePricingForm>
                        containerClassName={styles.input}
                        disabled={modifierKind !== "DISCOUNT"}
                        name="discount"
                        placeholder="Rabat"
                        type="number"
                      />
                      <Typography
                        className="d-flex align-items-center"
                        color={modifierKind === "DISCOUNT" ? "neutralBlack64" : "neutralBlack48"}
                        fontSize="12"
                        fontWeight="600"
                      >
                        %
                      </Typography>
                    </div>
                  </div>
                  <div className="d-flex flex-column gap-1 pb-1">
                    <Radio
                      checked={modifierKind === "MARGIN"}
                      label="Nalicz marżę"
                      onClick={() => {
                        setModifierKind("MARGIN");
                        setFieldValue("discount", 0);
                      }}
                      size="sm"
                    />
                    <div className={styles.inputSection}>
                      <TextField.Form<CreatePricingForm>
                        containerClassName={styles.input}
                        disabled={modifierKind !== "MARGIN"}
                        name="margin"
                        placeholder="Marża"
                        type="number"
                      />
                      <Typography
                        className="d-flex align-items-center"
                        color={modifierKind === "MARGIN" ? "neutralBlack64" : "neutralBlack48"}
                        fontSize="12"
                        fontWeight="600"
                      >
                        %
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column gap-2 pb-2">
                  <Select.Form<CreatePricingForm>
                    items={priceListsOptions}
                    label="Cennik (opcjonalnie)"
                    name="priceListId"
                    textFieldProps={{ containerClassName: styles.priceListSelect }}
                  />
                </div>
                <div className={styles.dashedBorder} />
                <div className="d-flex flex-column gap-2">
                  <Typography color="neutralBlack48" fontSize="10" fontWeight="700">
                    {String("sposób naliczania ceny:").toUpperCase()}
                  </Typography>
                  {createDraftDocumentUtils.getPricingMethod(
                    { id: values.priceListId },
                    values.discount,
                    values.margin,
                    priceLists,
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between p-3">
                <div className="d-flex align-items-center gap-2">
                  <Button
                    className="text-uppercase"
                    onClick={close}
                    size="medium"
                    variant="transparent"
                  >
                    Anuluj
                  </Button>
                  <Button
                    className="text-uppercase"
                    isLoading={isSubmitting}
                    size="medium"
                    type="submit"
                    variant="deepPurple"
                  >
                    Gotowe
                  </Button>
                </div>
                <Button
                  className="text-uppercase"
                  onClick={() =>
                    resetPricesMutation.mutate({
                      tradingDocumentId: draftInvoice.id,
                    })
                  }
                  size="medium"
                  startIcon={MdiUndo}
                  variant="gray"
                >
                  Przywróć pierwotne ceny z zamówień
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
